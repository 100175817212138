import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar, Toolbar, Typography, Box, Tab, Tabs,
  Card, CardContent, Chip, CircularProgress, IconButton, Container
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RefreshIcon from '@mui/icons-material/Refresh';
import PullToRefresh from 'react-simple-pull-to-refresh';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    status: {
      active: '#ff3d00',
      resolved: '#00c853',
      maintenance: '#ffc400',
      investigating: '#2979ff'
    }
  },
});

const STATUS_TYPES = {
  ACTIVE: 'Active',
  RESOLVED: 'Resolved',
  MAINTENANCE: 'Maintenance',
  INVESTIGATING: 'Investigating',
  ALL: 'All'
};

function getStatusFromContent(title, description) {
  const lowerTitle = title.toLowerCase();
  
  // Create a temporary div to parse HTML content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = description;
  const textContent = tempDiv.textContent || tempDiv.innerText;
  const lowerDesc = textContent.toLowerCase();
  
  // First check the description for the most recent status
  const descLines = lowerDesc.split('\n').map(line => line.trim()).filter(line => line);
  
  // Check the first status line (most recent update)
  for (let line of descLines) {
    // Remove GMT timestamp if present
    line = line.replace(/gmt$/, '').trim();
    
    // Check for resolved/completed status first
    if (line.includes('resolved -') || line.includes('completed -')) {
      return STATUS_TYPES.RESOLVED;
    }

    // Check for maintenance status
    if (line.includes('maintenance -')) {
      // If it's maintenance, look ahead in the description for "completed"
      const isCompleted = descLines.some(l => 
        l.includes('completed -') || 
        l.includes('maintenance completed') ||
        l.includes('has been completed')
      );
      if (isCompleted) {
        return STATUS_TYPES.RESOLVED;
      }
      return STATUS_TYPES.MAINTENANCE;
    }

    if (line.includes('investigating -')) {
      return STATUS_TYPES.INVESTIGATING;
    }
    if (line.includes('monitoring -')) {
      return STATUS_TYPES.ACTIVE;
    }
    if (line.includes('identified -')) {
      return STATUS_TYPES.ACTIVE;
    }
  }
  
  // If no status found in description, check title
  if (lowerTitle.includes('maintenance') || 
      lowerTitle.includes('scheduled')) {
    // Also check if maintenance is completed in description
    const isCompleted = descLines.some(l => 
      l.includes('completed -') || 
      l.includes('maintenance completed') ||
      l.includes('has been completed')
    );
    if (isCompleted) {
      return STATUS_TYPES.RESOLVED;
    }
    return STATUS_TYPES.MAINTENANCE;
  }
  
  if (lowerTitle.includes('investigating') || 
      lowerTitle.includes('investigation')) {
    return STATUS_TYPES.INVESTIGATING;
  }

  // If it contains 'issue' or 'problem' but not resolved, it's active
  if (lowerTitle.includes('issue') || 
      lowerTitle.includes('problem') || 
      lowerTitle.includes('disruption') ||
      lowerTitle.includes('outage')) {
    return STATUS_TYPES.ACTIVE;
  }

  // Default to Active if no other status matches
  return STATUS_TYPES.ACTIVE;
}

function getStatusColor(status) {
  switch (status) {
    case STATUS_TYPES.ACTIVE:
      return 'error';
    case STATUS_TYPES.RESOLVED:
      return 'success';
    case STATUS_TYPES.MAINTENANCE:
      return 'warning';
    default:
      return 'default';
  }
}

function parseRSSFeed(xmlText) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
  const items = xmlDoc.querySelectorAll('item');
  
  return Array.from(items).map(item => {
    const title = item.querySelector('title')?.textContent || '';
    const description = item.querySelector('description')?.textContent || '';
    const status = getStatusFromContent(title, description);
    
    return {
      title,
      description,
      pubDate: item.querySelector('pubDate')?.textContent || '',
      link: item.querySelector('link')?.textContent || '',
      status
    };
  });
}

function App() {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(STATUS_TYPES.ACTIVE);
  const [refreshing, setRefreshing] = useState(false);

  const fetchFreshData = async () => {
    try {
      // Try multiple CORS proxies in case one fails
      const corsProxies = [
        'https://corsproxy.io/?',
        'https://api.allorigins.win/raw?url=',
        'https://cors-anywhere.herokuapp.com/'
      ];

      let response = null;
      let error = null;

      for (const proxy of corsProxies) {
        try {
          const feedUrl = 'https://www.fasthosts-status.co.uk/history.rss';
          response = await axios.get(proxy + encodeURIComponent(feedUrl));
          
          if (response.data) {
            break; // Successfully got data, exit the loop
          }
        } catch (e) {
          error = e;
          console.log('Proxy failed:', proxy, e.message);
          continue; // Try next proxy
        }
      }

      if (!response?.data) {
        throw error || new Error('No data received from feed');
      }

      const feedItems = parseRSSFeed(response.data);
      
      if (feedItems.length === 0) {
        throw new Error('No items found in feed');
      }

      // Cache the fresh data
      localStorage.setItem('statusFeedCache', JSON.stringify(feedItems));
      localStorage.setItem('statusFeedTimestamp', Date.now().toString());

      setFeed(feedItems);
      setLoading(false);
      setRefreshing(false);
      return feedItems;
    } catch (error) {
      console.error('Feed fetch error:', error);
      throw new Error('Unable to load status updates. Please check your internet connection and try again.');
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchFreshData();
    } catch (error) {
      console.error('Error refreshing feed:', error.message);
      setError('Failed to refresh status updates. Please try again later.');
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        // Try to get cached data first
        const cachedData = localStorage.getItem('statusFeedCache');
        const cachedTimestamp = localStorage.getItem('statusFeedTimestamp');
        
        if (cachedData && cachedTimestamp) {
          const parsedData = JSON.parse(cachedData);
          const timestamp = parseInt(cachedTimestamp);
          
          // Use cached data if it's less than 5 minutes old
          if (Date.now() - timestamp < 5 * 60 * 1000) {
            setFeed(parsedData);
            setLoading(false);
            // Fetch fresh data in background
            fetchFreshData().catch(console.error);
            return;
          }
        }
        
        // No valid cache, fetch fresh data
        await fetchFreshData();
      } catch (error) {
        console.error('Error loading status updates:', error.message);
        setError('Failed to load status updates. Please try again later.');
        setLoading(false);
      }
    };

    fetchFeed();
  }, []);

  const filteredFeed = feed.filter(item => 
    currentTab === STATUS_TYPES.ALL || item.status === currentTab
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              REMUS Status
            </Typography>
            <IconButton 
              color="inherit" 
              onClick={handleRefresh}
              disabled={refreshing}
            >
              <RefreshIcon sx={{ 
                animation: refreshing ? 'spin 1s linear infinite' : 'none',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }} />
            </IconButton>
          </Toolbar>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ bgcolor: 'primary.dark' }}
          >
            <Tab label="Active" value={STATUS_TYPES.ACTIVE} />
            <Tab label="Resolved" value={STATUS_TYPES.RESOLVED} />
            <Tab label="Maintenance" value={STATUS_TYPES.MAINTENANCE} />
            <Tab label="All" value={STATUS_TYPES.ALL} />
          </Tabs>
        </AppBar>

        <PullToRefresh
          onRefresh={handleRefresh}
          pullingContent=""
          refreshingContent={
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          }
          resistance={2}
        >
          <Box sx={{ 
            height: '100%',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            '-webkit-overflow-scrolling': 'touch'
          }}>
            <Container maxWidth="sm" sx={{ py: 2 }}>
              {error ? (
                <Typography color="error" align="center">{error}</Typography>
              ) : loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : filteredFeed.length === 0 ? (
                <Typography align="center">No {currentTab.toLowerCase()} incidents</Typography>
              ) : (
                filteredFeed.map((item, index) => (
                  <Card key={index} sx={{ mb: 2, borderRadius: 2 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Typography variant="h6" gutterBottom sx={{ flex: 1 }}>
                          {item.title}
                        </Typography>
                        <Chip 
                          label={item.status}
                          size="small"
                          sx={{
                            ml: 1,
                            bgcolor: theme.palette[getStatusColor(item.status)].main,
                            color: 'white',
                          }}
                        />
                      </Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        {new Date(item.pubDate).toLocaleString()}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ textAlign: 'left' }}
                        dangerouslySetInnerHTML={{ __html: item.description }} 
                      />
                    </CardContent>
                  </Card>
                ))
              )}
            </Container>
          </Box>
        </PullToRefresh>
      </Box>
    </ThemeProvider>
  );
}

export default App;
